import { useState } from "react";
import renderLSUnavailable from "./lsUnavail";
import timeFormat from "../../../TimeFormatter/TimeFormatter";
import { MDBRadio, MDBRange } from "mdb-react-ui-kit";
import { acceptableLocales } from "../../esd/audio/consts";
import renderGeoAPIUnavailable from "./geoAPIUnavail";

export default function StopInfoSettings(props) {
    const [tempState, setTempState] = useState(0)
    const [settingsMgr, setSettingsMgr] = useState(props.settingsMgr)
    const [dataMgr, setDataMgr] = useState(props.dataMgr)

    const onRadioChange = (e) => {
        props.settingsMgr.set(e.currentTarget.name, e.currentTarget.value)
        setTempState(tempState + 1)
    }

    const setPronunciationPlaybackSpeed = (e) => {
        props.settingsMgr.set("pronunciationPlaybackSpeed", parseFloat(e.currentTarget.value))
        setTempState(tempState + 1)
    }

    const getSetting = (key) => {
        return props.settingsMgr.get(key)
    }

    const renderLocalArrivalTime = () => {
        let testTime = timeFormat(0, 1679411092, 0, "esd_local", "America/New_York", "en-US", props.settingsMgr)
        if (testTime.indexOf("Invalid DateTime") === -1) {
            return (
                <>
                    <hr></hr>
                    <h5>Show local arrival time in stop info window</h5>
                    <MDBRadio name='esdLocalTimeVisible' id='esdLocalTimeVisible-on' value={true} label='On' inline checked={getSetting("esdLocalTimeVisible")} onChange={onRadioChange} />
                    <MDBRadio name='esdLocalTimeVisible' id='esdLocalTimeVisible-off' value={false} label='Off' inline checked={!getSetting("esdLocalTimeVisible")} onChange={onRadioChange} /><br></br>
                    <small>Show the local arrival time of the Easter Bunny in the stop information window.</small>
                </>
            )
        } else {
            return (<></>)
        }
    }
    
    const renderAutomaticPronunciationLocale = () => {
        if (acceptableLocales.indexOf(navigator.language) !== -1) {
            switch (navigator.language) {
                case "en-US":
                    return "American English"
                case "en-AU":
                    return "Australian English"
                case "en-GB":
                    return "British English"
                case "en-IE": 
                    return "Irish English"
                case "en-NZ":
                    return "New Zealand English"
                case "en-ZA":
                    return "South African English"
                default:
                    return "American English"
            }
        } else {
            return "American English"
        }
    }

    return (
        <>
            {renderLSUnavailable(settingsMgr.ls_available)}
            {renderGeoAPIUnavailable(props.geoMetricsErrorState)}
            <h5>Show weather summary in stop info window</h5>
            <MDBRadio name='esdWeatherSummaryVisible' id='esdWeatherSummaryVisible-on' value={true} label='On' inline checked={getSetting("esdWeatherSummaryVisible")} onChange={onRadioChange} />
            <MDBRadio name='esdWeatherSummaryVisible' id='esdWeatherSummaryVisible-off' value={false} label='Off' inline checked={!getSetting("esdWeatherSummaryVisible")} onChange={onRadioChange} /><br></br>
            <small>Show a text summary of the weather conditions in the stop information window. You can hover over the icon to see a summary of the weather conditions when this feature is off.</small>
            {renderLocalArrivalTime()}
            <hr></hr>
            <h5>Stop pronunciation playback speed</h5>
            <div>
                <small style={{ float: "left" }}>0.5x</small>
                <small style={{ float: "right" }}>2.0x</small>
            </div>
            <MDBRange name='pronunciationPlaybackSpeed' id='pronunciationPlaybackSpeed' defaultValue={settingsMgr.get("pronunciationPlaybackSpeed").toString()} onChange={setPronunciationPlaybackSpeed} min="0.5" max="2.0" step="0.1" />
            <small>Change the playback speed of the stop pronunciation. Playback speed is currently set to <b>{getSetting("pronunciationPlaybackSpeed")}x</b>.</small>
            <hr></hr>
            <h5>Stop pronunciation language</h5>
            <MDBRadio name='pronunciationLocale' id='pronunciationLocale-automatic' value={"automatic"} label={'Automatic (' + renderAutomaticPronunciationLocale() + ')'} inline checked={getSetting("pronunciationLocale") === "automatic"} onChange={onRadioChange} />
            <MDBRadio name='pronunciationLocale' id='pronunciationLocale-en-US' value={"en-US"} label={"American English"} inline checked={getSetting("pronunciationLocale") === "en-US"} onChange={onRadioChange} />
            <MDBRadio name='pronunciationLocale' id='pronunciationLoacle-en-AU' value={"en-AU"} label={"Australian English"} inline checked={getSetting("pronunciationLocale") === "en-AU"} onChange={onRadioChange} />
            <MDBRadio name='pronunciationLocale' id='pronunciationLocale-en-GB' value={"en-GB"} label={"British English"} inline checked={getSetting("pronunciationLocale") === "en-GB"} onChange={onRadioChange} /> 
            <MDBRadio name='pronunciationLocale' id='pronunciationLocale-en-IE' value={"en-IE"} label={"Irish English"} inline checked={getSetting("pronunciationLocale") === "en-IE"} onChange={onRadioChange} /> 
            <MDBRadio name='pronunciationLocale' id='pronunciationLocale-en-NZ' value={"en-NZ"} label={"New Zealand English"} inline checked={getSetting("pronunciationLocale") === "en-NZ"} onChange={onRadioChange} /> 
            <MDBRadio name='pronunciationLocale' id='pronunciationLocale-en-ZA' value={"en-ZA"} label={"South African English"} inline checked={getSetting("pronunciationLocale") === "en-ZA"} onChange={onRadioChange} /><br></br>
            <small>Change the language used in the stop pronunciation.</small>
        </>
    )
}