import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit"
import React, { useEffect, useState } from "react"

export default function ShutdownModal(props) {
    const [showShutdownModal, setShowShutdownModal] = useState(false)
    const [settingsMgr, setSettingsMgr] = useState(props.settingsMgr)
    const [dataMgr, setDataMgr] = useState(props.dataMgr)

    const onRouteStateChange = (e) => {
        if (e.detail.state === 3) {
            if (!settingsMgr.settings.haveShownShutdownModal && process.env.REACT_APP_SHUTDOWN_BANNERS_ENABLED === 'true') {
                setTimeout(() => {
                    settingsMgr.set("haveShownShutdownModal", true)
                    setShowShutdownModal(true)
                }, 5000)
            }
        }
    }
    
    useEffect(() => {
        document.addEventListener("onRouteStateChange", onRouteStateChange.bind(this))
        return () => {
            document.removeEventListener("onRouteStateChange", onRouteStateChange.bind(this))
        }
    })
    
    if (dataMgr.routeState === 3 && !settingsMgr.settings.haveShownShutdownModal && process.env.REACT_APP_SHUTDOWN_BANNERS_ENABLED === 'true') {
        setTimeout(() => {
            settingsMgr.set("haveShownShutdownModal", true)
            setShowShutdownModal(true)
        }, 3000)
    }

    return (
        <MDBModal id="shutdownModal" appendToBody show={showShutdownModal} setShow={setShowShutdownModal} tabIndex='-1'>
            <MDBModalDialog id="shutdownModalDialog">
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Thank you for 6 years of track.easterbunny.cc!</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={() => {setShowShutdownModal(!showShutdownModal)}} title="Click to close this modal"></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        {/* To future Owen - this shutdown message has good enough. It doesn't need to be changed. :) */}
                        As the Easter Bunny has arrived back at Easter Island, <b>this marks the end of track.easterbunny.cc after 6 incredible years of tracking</b>. Thank you for using, supporting, and sharing the tracker through these years - it has truly meant the world to me.<br></br><br></br>
                        Wishing you a safe, happy, and healthy Easter,<br></br>
                        - Owen
                        <hr></hr>
                        For more information on the shutdown of track.easterbunny.cc, <a href="/news/#pid-141" target="_blank" rel="noreferrer">read the announcement on the news page</a>.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={() => {setShowShutdownModal(!showShutdownModal)}} title="Click to close this modal">
                            Close
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}