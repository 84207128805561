import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit"
import React, { useState } from "react"
import renderGeoAPIUnavailable from "./geoAPIUnavail"
import renderLSUnavailable from "./lsUnavail"

export default function ResetSettings(props) {
    const [resetModal, setResetModal] = useState(false)
    const [streamerModal, setStreamerModal] = useState(false)
    const [lowPerfModal, setLowPerfModal] = useState(false)
    const [settingsMgr, setSettingsMgr] = useState(props.settingsMgr)

    const setResetModal_wrap = (value) => {
        if (value !== resetModal) {
            props.setSettingsModal(!value)
        }
        setResetModal(value)
    }

    const setStreamerModal_wrap = (value) => {
        if (value !== streamerModal) {
            props.setSettingsModal(!value)
        }
        setStreamerModal(value)
    }

    const setLowPerfModal_wrap = (value) => {
        if (value !== lowPerfModal) {
            props.setSettingsModal(!value)
        }

        setLowPerfModal(value)
    }

    const toggleResetModal = (noSettingsRelaunch) => {
        setTimeout(() => {
            document.getElementById("resetModalDialog").scrollIntoView()
        }, resetModal ? 250 : 5)
        setResetModal(!resetModal)
        if (noSettingsRelaunch !== true) {
            props.setSettingsModal(resetModal)
        }
    }
    const toggleStreamerModal = (noSettingsRelaunch) => {
        setTimeout(() => {
            document.getElementById("streamerModalDialog").scrollIntoView()
        }, streamerModal ? 250 : 5)
        setStreamerModal(!streamerModal)
        if (noSettingsRelaunch !== true) {
            props.setSettingsModal(streamerModal)
        }
    }

    const toggleLowPerfModal = (noSettingsRelaunch) => {
        setTimeout(() => {
            document.getElementById("lowPerfModalDialog").scrollIntoView()
        }, lowPerfModal ? 250 : 5)
        setLowPerfModal(!lowPerfModal)
        if (noSettingsRelaunch !== true) {
            props.setSettingsModal(lowPerfModal)
        }
    }

    const renderStreamerChromeField = () => {
        if (props.settingsMgr.is_chromium_browser()) {
            return (<li>The <b>Chrome map fix</b> is set to <b>On</b> so you don't see grey lines on the map since you're using a Chrome-based browser.</li>)
        } else {
            return ""
        }
    }

    return (
        <>
            {renderLSUnavailable(settingsMgr.ls_available)}
            {renderGeoAPIUnavailable(props.geoMetricsErrorState)}
            <h5>Reset settings to default</h5>
            <MDBBtn onClick={toggleResetModal} style={{ marginTop: "6px", marginBottom: "5px" }} title="Click to confirm if you want to reset settings to default">Reset settings to default</MDBBtn><br></br>
            <small>Resets all settings to their defaults.</small>
            <hr></hr>
            <h5>Optimize settings for live streaming</h5>
            <MDBBtn onClick={toggleStreamerModal} style={{ marginTop: "6px", marginBottom: "5px" }} title="Click to confirm if you want to reset settings to optimized for live streaming">Optimize settings for live streaming</MDBBtn><br></br>
            <small>Configure the tracker to settings optimized for live streaming.</small>
            <hr></hr>
            <h5>Optimize settings for low performance</h5>
            <MDBBtn onClick={toggleLowPerfModal} style={{ marginTop: "6px", marginBottom: "5px" }} title="Click to confirm if you want to reset settings to optimized for low performance">Optimize settings for low performance</MDBBtn><br></br>
            <small>Configure the tracker to settings optimized for devices with low performance, or to save battery power.</small>
            <MDBModal id="resetModal" appendToBody show={resetModal} setShow={setResetModal_wrap} tabIndex='-1'>
                <MDBModalDialog id="resetModalDialog">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Reset settings to default?</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleResetModal} title="Click to close this modal"></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            This will reset any customized tracker settings back to their defaults. This action can't be undone.<br></br><br></br>
                            If you're okay with this, click the confirm button. Otherwise, click the cancel button.
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={toggleResetModal} title="Click to close this modal">
                                Close
                            </MDBBtn>
                            <MDBBtn color="primary" onClick={() => {props.settingsMgr.set_defaults(); toggleResetModal(true); props.setActiveSettingsTab('tracker')}} title="Click to reset tracker settings to default">
                                Confirm
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <MDBModal id="lowPerfModal" appendToBody show={lowPerfModal} setShow={setLowPerfModal_wrap} tabIndex='-1'>
                <MDBModalDialog id="lowPerfModalDialog">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Optimize settings for low performance?</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleLowPerfModal} title="Click to close this modal"></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            If you're experiencing poor tracker performance on your device (or want to save battery power on mobile devices), we provide a set of settings that can help improve the performance of the tracker at the expense of disabling some features. In short:<br></br><br></br>
                            <ul>
                                <li>The <b>Tracker smoothness</b> is set to <b>Slowest</b> so the tracker isn't updating as frequently, which should improve performance.</li>
                                <li>The <b>Easter Bunny bouncing effect</b> is set to <b>Off</b> to improve performance.</li>
                                <li>The <b>Zoom on stop arrival</b> is set to <b>Off</b> to prevent lag whenever the Easter Bunny arrives at a stop.</li>
                                <li>The <b>Map style</b> is set to <b>Street</b> as it's the easiest to render.</li>
                                <li>The <b>Show arrival times when hovering over stop icons</b> is set to <b>Off</b> to improve performance.</li>
                                <li>The <b>Metrics shown</b> is set to just <b>Baskets delivered</b> since rendering multiple metrics can cause performance issues</li>
                            </ul>
                            Otherwise, all remaining tracker settings will be kept just the way you have them. <br></br><br></br>
                            If you're okay with these settings being changed, click on the confirm button. Otherwise, click on the cancel button.
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={toggleLowPerfModal} title="Click to close this modal">
                                Close
                            </MDBBtn>
                            <MDBBtn color="primary" onClick={() => {props.settingsMgr.set_lowperf(); toggleLowPerfModal(true); props.setActiveSettingsTab('tracker')}} title="Click to reset tracker settings to optimized for low performance">
                                Confirm
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <MDBModal id="streamerModal" appendToBody show={streamerModal} setShow={setStreamerModal_wrap} tabIndex='-1'>
                <MDBModalDialog id="streamerModalDialog">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Optimize settings for live streaming?</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleStreamerModal} title="Click to close this modal"></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            We provide a set of settings that have been commonly used when streaming track.easterbunny.cc on online platforms for you to use. In short:<br></br><br></br>
                            <ul>
                                <li>The <b>Site appearance</b> is set to <b>Dark</b> and the <b>Map style</b> is set to <b>Hybrid</b>. This style is preferred by viewers.</li>
                                <li>The <b>Metrics shown</b> is set to <b>All aside from Distance from you</b> so viewers can see all the stats about the Easter Bunny's journey.</li>
                                <li>The <b>Easter Bunny bouncing effect</b> is set to <b>On</b> as viewers prefer the bouncing effect.</li>
                                <li>The <b>Tracker smoothness</b> is set to <b>Smoothest</b>. Feel free to lower this if your machine lags on Smoothest.</li>
                                <li>The <b>Tracker units</b> is set to <b>Imperial</b>.</li>
                                { renderStreamerChromeField() }
                                <li>The <b>Easter Bunny estimated arrival time</b> is set to <b>Off</b> to protect your privacy on stream.</li>
                                <li>The <b>Hide distance from you metric</b> is set to <b>On</b> so you cannot expose your location on stream.</li>
                            </ul>
                            All remaining tracker settings are set to their defaults. If you're okay with these settings being changed, click the confirm button. Otherwise, click the cancel button.
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={toggleStreamerModal} title="Click to close this modal">
                                Close
                            </MDBBtn>
                            <MDBBtn color="primary" onClick={() => {props.settingsMgr.set_streamer(); toggleStreamerModal(true); props.setActiveSettingsTab('tracker')}} title="Click to reset tracker settings to optimized for live streaming">
                                Confirm
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}