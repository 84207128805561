import { MDBRadio, MDBTooltip } from "mdb-react-ui-kit"
import React, { useState } from "react"
import renderLSUnavailable from "./lsUnavail"
import renderGeoAPIUnavailable from "./geoAPIUnavail"
import getBrowserVersionInt from "../../../utils/getBrowserVersionInt"
import getBrowserVersionInt2 from "../../../utils/getBrowserVersionInt2"

export default function TrackerSettings(props) {
    // Yeah I'd rather not use this but sadly b/c setState does all this trickery it's something we gotta go :/
    const [tempState, setTempState] = useState(0)
    const [settingsMgr, setSettingsMgr] = useState(props.settingsMgr)
    const [dataMgr, setDataMgr] = useState(props.dataMgr)

    const onRadioChange = (e) => {
        props.settingsMgr.set(e.currentTarget.name, e.currentTarget.value)
        setTempState(tempState + 1)
    }

    const getSetting = (key) => {
        return props.settingsMgr.get(key)
    }
    
    const renderAutomaticAppearanceField = () => {
        return "Automatic (" + props.settingsMgr.get_pcs_appearance() + ")" 
    }

    const renderEBArrivalText = () => {
        if (dataMgr.routeState === 0) {
            return "Show when the Easter Bunny is estimated to arrive at your location during tracking. Turn this off if you're live streaming the tracker."
        } else if (dataMgr.routeState === 1) {
            return "Show when the Easter Bunny is estimated to arrive at your location once the Easter Bunny lifts off. Turn this off if you're live streaming the tracker."
        } else if (dataMgr.routeState === 2) {
            return "Show when the Easter Bunny is estimated to arrive at your location. Turn this off if you're live streaming the tracker."
        } else if (dataMgr.routeState === 3) {
            return "Show when the Easter Bunny is estimated to arrive at your location. Since tracking is over, an estimated arrival time isn't visible and this setting will apply for the next tracker run."
        }
    }

    const renderSamsungInternetWarning = () => {
        if (settingsMgr.browser.browser.name === "Samsung Internet for Android") {
            return (
                <>
                <b>Note: </b>Your browser doesn't support changing tracker appearance from the appearance your device is in. You'll need to change it manually.<br></br><br></br>
                </>
            )
        }
    }   

    const renderTrackerSmoothnessText = () => {
        if (dataMgr.routeState <= 1) {
            return "Control how frequently metrics and the Easter Bunny marker on the map update. This setting will take effect when tracking begins. You can hover over each setting to see the update rates for that setting. Smoother settings increase CPU usage, which will decrease battery life on mobile devices."
        } else if (dataMgr.routeState === 2) {
            return "Control how frequently metrics and the Easter Bunny marker on the map update. You can hover over each setting to see the update rates for that setting. Smoother settings increase CPU usage, which will decrease battery life on mobile devices."
        } else if (dataMgr.routeState === 3) {
            return "Control how frequently metrics and the Easter Bunny marker on the map update. Because tracking is over, this setting won't apply until the next tracker run."
        }
    }

    const renderEBArrivalLowHeight = () => {
        if (window.innerHeight <= 500 || window.innerWidth <= 330) {
            return (
                <>
                <b>Note: </b>Enabling this setting may cause the Easter Bunny icon to get obstructed and/or buttons to overlap the top boxes.<br></br><br></br>
                </>
            )
        } else {
            return (<></>)
        }
    }

    const renderSafariFsNudge = () => {
        try {
            if (props.settingsMgr.browser.browser.name === "Safari" && props.settingsMgr.browser.os.name === "iPadOS" && (getBrowserVersionInt(props.settingsMgr) > 16 || (getBrowserVersionInt(props.settingsMgr) === 16 && getBrowserVersionInt2(props.settingsMgr) >= 4))) {
                return (
                    <>
                    <hr></hr>
                    <h5>Nudge down top boxes in fullscreen</h5>
                    <MDBRadio name='safariFsNudgeDown' id='safariFsNudgeDown-true' value={true} label='On' inline checked={getSetting("safariFsNudgeDown")} onChange={onRadioChange} />
                    <MDBRadio name='safariFsNudgeDown' id='safariFsNudgeDown-false' value={false} label='Off' inline checked={!getSetting("safariFsNudgeDown")} onChange={onRadioChange} /><br></br>
                    <small>When using Safari on iPadOS 16.4 or higher in fullscreen, the exit fullscreen button will appear on top of the top boxes. If you'd like to make it so the top boxes nudge down and avoid this button when in fullscreen, enable this setting.</small>
                    </>
                )
            } else {
                return (<></>)
            }
        } catch (e) {
            return (<></>)
        }
    }
    
    return (
        <>
            {renderLSUnavailable(settingsMgr.ls_available)}
            {renderGeoAPIUnavailable(props.geoMetricsErrorState)}
            <h5>Tracker appearance</h5>
            <MDBRadio name='appearance' id='appearance-automatic' value='automatic' label={renderAutomaticAppearanceField()} inline checked={getSetting("appearance") === "automatic"} onChange={onRadioChange} />
            <MDBRadio name='appearance' id='appearance-light' value='light' label='Light' inline checked={getSetting("appearance") === "light"} onChange={onRadioChange} />
            <MDBRadio name='appearance' id='appearance-dark' value='dark' label='Dark' inline checked={getSetting("appearance") === "dark"} onChange={onRadioChange} /><br></br>
            <small>{renderSamsungInternetWarning()}Change the tracker appearance. Automatic uses the appearance mode your device is in.</small>
            <hr></hr>
            <h5>Easter Bunny estimated arrival time</h5>
            <MDBRadio name='ebArrivalVisible' id='ebArrivalVisible-true' value={true} label='On' inline checked={getSetting("ebArrivalVisible")} onChange={onRadioChange} />
            <MDBRadio name='ebArrivalVisible' id='ebArrivalVIsible-false' value={false} label='Off' inline checked={!getSetting("ebArrivalVisible")} onChange={onRadioChange} /><br></br>
            <small>{renderEBArrivalLowHeight()}{renderEBArrivalText()}</small>
            <hr></hr>
            <h5>Tracker smoothness</h5>
            <MDBRadio name='smoothMovement' id='smoothMovement-slowest' value='slowest' label={
                <MDBTooltip tag="span" title="Metric updates at 5 FPS, Map updates at 1 FPS, arrival animation at 15 FPS">
                    Slowest
                </MDBTooltip>
            } inline checked={getSetting("smoothMovement") === "slowest"} onChange={onRadioChange} />
            <MDBRadio name='smoothMovement' id='smoothMovement-slower' value='slower' label={
                <MDBTooltip tag="span" title="Metric updates at 10 FPS, Map updates at 2 FPS, arrival animation at 30 FPS">
                    Slower
                </MDBTooltip>
            } inline checked={getSetting("smoothMovement") === "slower"} onChange={onRadioChange} />
            <MDBRadio name='smoothMovement' id='smoothMovement-default' value='default' label={
                <MDBTooltip tag="span" title="Metric updates at 10 FPS, Map updates at 5 FPS, arrival animation at 30 FPS">
                    Default
                </MDBTooltip>
            } inline checked={getSetting("smoothMovement") === "default"} onChange={onRadioChange} />
            <MDBRadio name='smoothMovement' id='smoothMovement-smoother' value='smoother' label={
                <MDBTooltip tag="span" title="Metric updates at 20 FPS, Map updates at 10 FPS, arrival animation at 60 FPS">
                    Smoother
                </MDBTooltip>
            } inline checked={getSetting("smoothMovement") === "smoother"} onChange={onRadioChange} />
            <MDBRadio name='smoothMovement' id='smoothMovement-smoothest' value='smoothest' label={
                <MDBTooltip tag="span" title="Metric updates at 20 FPS, Map updates at 20 FPS, arrival animation at 60 FPS">
                    Smoothest
                </MDBTooltip>
            } inline checked={getSetting("smoothMovement") === "smoothest"} onChange={onRadioChange} /><br></br>
            <small>{renderTrackerSmoothnessText()}</small>
            {renderSafariFsNudge()}
        </>
    )
}